"use client";

import type { Metadata } from "next";
import Error from "next/error";

export const metadata: Metadata = {
  robots: {
    index: false,
    follow: false,
  },
};

/**
 * To specifically handle errors in root layout.js, use a variation of error.js called app/global-error.js
 * located in the root app directory.
 * https://nextjs.org/docs/app/api-reference/file-conventions/error#global-errorjs
 * This will never happen since we pass children directly in root layout.
 */
export default function RootError() {
  return (
    <html lang="en">
      <body>
        <Error statusCode={500} />
      </body>
    </html>
  );
}
